var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-alert",
                { attrs: { text: "", prominent: "", color: "info" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "subtitle-2 mr-3 text-uppercase font-weight-bold",
                        },
                        [_vm._v("invite to:")]
                      ),
                      _c("v-select", {
                        attrs: {
                          loading: _vm.fetchingInstances,
                          disabled: _vm.fetchingInstances,
                          items: _vm.instanceNames,
                          label: "Target instance",
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.selectedInstance,
                          callback: function ($$v) {
                            _vm.selectedInstance = $$v
                          },
                          expression: "selectedInstance",
                        },
                      }),
                      _c("v-spacer"),
                      _c(
                        "add-instance-dialog",
                        {
                          on: {
                            inviteUsers: function ($event) {
                              return _vm.setInstanceNameToTheNewInstance(
                                $event.instanceName
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            { attrs: { color: "primary" } },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("add"),
                              ]),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold caption" },
                                [_vm._v("Add New Instance")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.selectedInstance
                ? _c(
                    "div",
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { disabled: _vm.numInvitationsToSend !== 0 },
                          model: {
                            value: _vm.invitationType,
                            callback: function ($$v) {
                              _vm.invitationType = $$v
                            },
                            expression: "invitationType",
                          },
                        },
                        _vm._l(_vm.invitationTypes, function (mode) {
                          return _c(
                            "div",
                            { key: mode.type },
                            [
                              _c("v-radio", {
                                staticClass: "mt-3 caption",
                                attrs: {
                                  color: "primary",
                                  value: mode.value,
                                  label: mode.type,
                                },
                              }),
                              _c(
                                "span",
                                {
                                  class: [
                                    _vm.invitationType === mode.value
                                      ? "caption secondary--text"
                                      : "caption",
                                  ],
                                },
                                [_vm._v(_vm._s(mode.description))]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm.invitationType
                        ? _c(
                            "v-form",
                            {
                              ref: "form",
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  required: "",
                                  rows: "3",
                                  "auto-grow": "",
                                  disabled: _vm.numInvitationsToSend !== 0,
                                  outlined: "",
                                },
                                on: { input: _vm.updateEmailString },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [_vm._v("Email addresses")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2885483575
                                ),
                                model: {
                                  value: _vm.emailsInput,
                                  callback: function ($$v) {
                                    _vm.emailsInput = $$v
                                  },
                                  expression: "emailsInput",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.emailsInput.length
                        ? _c("div", { staticClass: "mt-3" }, [
                            _c("h3", { staticClass: "secondary--text" }, [
                              _vm._v(
                                " The following users will receive an invitation email to become "
                              ),
                              _vm.invitationType === "instanceEditors"
                                ? _c("span", [_vm._v("editors")])
                                : _vm._e(),
                              _vm.invitationType === "instanceViewers"
                                ? _c("span", [_vm._v("viewers")])
                                : _vm._e(),
                              _vm._v(
                                ' of "' + _vm._s(_vm.selectedInstance) + '" '
                              ),
                            ]),
                            _c(
                              "div",
                              _vm._l(_vm.emails, function (email, index) {
                                return _c(
                                  "ul",
                                  { key: index, staticClass: "mt-3" },
                                  [
                                    email.status ===
                                      _vm.emailValidationOutcomes
                                        .LOW_RISK_EMAIL ||
                                    email.status ===
                                      _vm.emailValidationOutcomes
                                        .UNKNOWN_RISK_EMAIL
                                      ? _c(
                                          "li",
                                          [
                                            _vm._v(
                                              " " + _vm._s(email.email) + " "
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-1",
                                                attrs: {
                                                  "x-small": "",
                                                  color: "success",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("check_circle")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-uppercase font-weight-bold",
                                                  },
                                                  [_vm._v("validated")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : email.status ===
                                        _vm.emailValidationOutcomes
                                          .HIGH_RISK_EMAIL
                                      ? _c(
                                          "li",
                                          [
                                            _vm._v(
                                              " " + _vm._s(email.email) + " "
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-1",
                                                attrs: {
                                                  "x-small": "",
                                                  color: "error",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("cancel")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-uppercase font-weight-bold",
                                                  },
                                                  [_vm._v("validation failed")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : email.status ===
                                        _vm.emailValidationOutcomes
                                          .UNVALIDATED_EMAIL
                                      ? _c(
                                          "li",
                                          [
                                            _vm._v(
                                              " " + _vm._s(email.email) + " "
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-1",
                                                attrs: {
                                                  "x-small": "",
                                                  color: "secondary",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("warning")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-uppercase font-weight-bold",
                                                  },
                                                  [_vm._v("validation error")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm.validatingEmails
                                      ? _c(
                                          "li",
                                          [
                                            _vm._v(
                                              " " + _vm._s(email.email) + " "
                                            ),
                                            _c("v-progress-circular", {
                                              staticClass: "ml-1",
                                              attrs: {
                                                size: 20,
                                                indeterminate: "",
                                                color: "primary",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c("li", [_vm._v(_vm._s(email.email))]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("InvitationErrorsDialog", {
                attrs: {
                  showDialog: _vm.showInvitationErrorsDialog,
                  errorType: _vm.validationErrorType,
                  invalidEmails: _vm.invalidEmailsList,
                },
                on: {
                  proceedWithTheInvitation: function ($event) {
                    return _vm.inviteUsers($event.value)
                  },
                  validateEmailsAgain: function ($event) {
                    return _vm.validateEmailsAndInviteUsers($event.value)
                  },
                },
              }),
            ],
            1
          ),
          _c("v-card-actions", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-center justify-center flex-column w-100",
              },
              [
                _vm.$store.state.userInfo.is_sysadmin
                  ? _c("v-checkbox", {
                      staticClass: "mb-1 mt-5 pa-0",
                      attrs: {
                        label: "Generate invitations but don't send emails",
                      },
                      model: {
                        value: _vm.generateInvitationLinksOnly,
                        callback: function ($$v) {
                          _vm.generateInvitationLinksOnly = $$v
                        },
                        expression: "generateInvitationLinksOnly",
                      },
                    })
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "text-md-center mr-1",
                    attrs: {
                      loading:
                        _vm.numInvitationsToSend > 0 ||
                        _vm.inviting ||
                        _vm.validatingEmails,
                      disabled:
                        !_vm.valid ||
                        !_vm.emailsInput ||
                        _vm.numInvitationsToSend > 0 ||
                        _vm.validatingEmails,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.validateEmailsAndInviteUsers(true)
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                    _vm._v(" Invite "),
                  ],
                  1
                ),
                _vm.error
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mt-4 w-100",
                        attrs: { color: "error", icon: "warning", text: "" },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Invitation Failure Error"),
                          ]),
                          _vm._v(" Failed to invite the following users "),
                          _c(
                            "ul",
                            _vm._l(
                              _vm.failedInvitations,
                              function (email, index) {
                                return _c("li", { key: index }, [
                                  _vm._v(" " + _vm._s(email) + " "),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }